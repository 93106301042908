import AbstractService from '../abstract-service';
import AddToCartParametersInterface from '../../vo/event/add-to-cart-parameters-interface';
import CheckoutParametersInterface from '../../vo/event/checkout-parameters-interface';
import ClickEventParametersInterface from '../../vo/event/click-event-parameters-interface';
import EventParametersInterface from '../../vo/event/event-parameters-interface';
import GoogleConfigurationDto from '../../vo/google/google-configuration-dto';
import GoogleConfigurationInterface from '../../vo/google/google-configuration-interface';
import GoogleConfigurationParametersInterface from '../../vo/google/google-configuration-parameters-interface';
import GoogleEventParametersInterface from '../../vo/google/google-event-parameters-interface';
import ItemParametersInterface from '../../vo/event/item-parameters-interface';
import OrderParametersInterface from '../../vo/event/order-parameters-interface';
import PageViewParametersInterface from '../../vo/event/page-view-parameters-interface';
import ServiceInterface from '../service-interface';
import SubmitEventParametersInterface from '../../vo/event/submit-event-parameters-interface';
import ItemViewParametersInterface from '../../vo/event/item-view-parameters-interface';

declare global
{
    interface Window
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any;
    }
}

const gtag: Gtag.Gtag = function()
{
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
};

export default class GoogleAnalytics extends AbstractService implements ServiceInterface
{
    protected configuration: GoogleConfigurationInterface;

    public constructor(configuration: GoogleConfigurationInterface)
    {
        super(configuration);

        this.configuration = Object.assign(new GoogleConfigurationDto(), configuration);
    }

    public define(): void
    {
        if (this.defined)
        {
            return;
        }

        this.addScript(`https://www.googletagmanager.com/gtag/js?id=${ this.configuration.key }`, true);

        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());

        gtag(
            'consent', 'default', this.consentParameters,
        );

        gtag(
            'config', this.configuration.key, this.configParameters,
        );

        this.setDefined();
    }

    protected get consentParameters(): Gtag.ConsentParams
    {
        const consentParameters: Gtag.ConsentParams = {};

        if (this.configuration.consent === false)
        {
            consentParameters.ad_storage = 'denied';
            consentParameters.analytics_storage = 'denied';
        }
        else
        {
            consentParameters.ad_storage = 'granted';
            consentParameters.analytics_storage = 'granted';
        }

        return consentParameters;
    }

    protected get configParameters(): GoogleConfigurationParametersInterface
    {
        const configParameters: GoogleConfigurationParametersInterface = {
            send_page_view: false,
        };

        if (this.configuration.userId)
        {
            configParameters.user_id = this.configuration.userId;
        }

        if (this.configuration.debug)
        {
            configParameters.debug_mode = true;
        }

        if (this.configuration.useBeacon)
        {
            configParameters.transport_type = 'beacon';
        }

        return configParameters;
    }

    public trackEvent(event: string, parameters: EventParametersInterface): void
    {
        const googleParameters: GoogleEventParametersInterface = this.getEventParameters(parameters);

        gtag(
            'event', event, googleParameters,
        );
    }

    public trackPageView(parameters: PageViewParametersInterface): void
    {
        if (!parameters.key)
        {
            parameters.key = this.configuration.key;
        }

        this.trackEvent('page_view', parameters);
    }

    public trackAddToCart(parameters: AddToCartParametersInterface): void
    {
        this.trackEvent('add_to_cart', parameters);
    }

    public trackCheckout(parameters: CheckoutParametersInterface): void
    {
        if (parameters.checkoutStep === 1)
        {
            this.trackEvent('begin_checkout', parameters);
        }
        else
        {
            this.trackEvent('checkout_progress', parameters);
        }
    }

    public trackClick(parameters: ClickEventParametersInterface): void
    {
        this.trackEvent('click', parameters);
    }

    public trackSubmission(parameters: SubmitEventParametersInterface): void
    {
        this.trackEvent('submit', parameters);
    }

    protected getEventParameters(parameters: EventParametersInterface): GoogleEventParametersInterface
    {
        const eventParameters: GoogleEventParametersInterface = {
            affiliation: parameters.site ?? undefined,
            checkout_option: parameters.checkoutOption ?? undefined,
            checkout_step: parameters.checkoutStep ?? undefined,
            coupon: parameters.voucher ?? undefined,
            currency: parameters.currency ?? undefined,
            description: parameters.description ?? undefined,
            event_category: parameters.category ?? undefined,
            event_label: parameters.label ?? undefined,
            id: parameters.id ?? undefined,
            method: parameters.method ?? undefined,
            non_interaction: parameters.nonInteraction ?? undefined,
            page_location: parameters.pageLocation ?? undefined,
            page_path: parameters.pagePath ?? undefined,
            page_title: parameters.pageTitle ?? undefined,
            send_to: parameters.key ?? undefined,
            shipping: parameters.shipping ?? undefined,
            tax: parameters.vat ?? undefined,
            transaction_id: parameters.transactionId ?? undefined,
            value: parameters.value ?? undefined,
        };

        if (parameters.items)
        {
            const items: Gtag.Item[] = [];

            for (const item of parameters.items)
            {
                items.push(this.orderItemToParameters(item));
            }

            eventParameters.items = items;
        }

        return this.filterParameters(eventParameters);
    }

    public trackOrder(order: OrderParametersInterface): void
    {
        this.trackEvent('purchase', order);
    }

    protected orderItemToParameters(item: ItemParametersInterface): Gtag.Item
    {
        const itemParameters: Gtag.Item = {
            id: item.id ?? undefined,
            name: item.title ?? undefined,
            price: item.price ?? undefined,
            quantity: item.quantity ?? undefined,
            category: item.category ?? undefined,
            brand: item.brand ?? undefined,
        };

        return this.filterParameters(itemParameters);
    }

    public trackItemView(parameters: ItemViewParametersInterface): void
    {
        this.trackEvent('view_item', parameters);
    }
}